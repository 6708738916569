.card-body {
    padding: 6px;
    background-color: #eee; /* Gris clair */
    border-radius: 10px;
}

.input-group {
    margin-bottom: 20px;
}

.input-group .form-control {
    border-radius: 5px 0 0 5px;
    border: none;
    background-color: #fff; /* Blanc */
    color: #000; /* Texte noir pour contraste */
}

.input-group .btn-primary,
.input-group .btn-secondary {
    border-radius: 0 5px 5px 0;
    border: none;
}

.list-group-item {
    background-color: #fff; /* Blanc */
    border: none;
    border-bottom: 1px solid #eee; /* Gris clair */
    color: #000; /* Texte noir pour contraste */
    padding: 15px 20px;
    margin-bottom: 15px; /* Ajout d'un peu plus d'espacement */
    border-radius: 5px;
}

.list-group-item:last-child {
    border-bottom: none;
}

.list-group-item div {
    font-size: 1rem;
}

.prompt-text {
    font-size: 1.25rem;
    font-weight: bold;
}

.category-text {
    font-size: 0.85rem;
}

.level-text {
    font-size: 0.85rem;
    font-weight: bold;
}

.text-muted {
    color: #9fa1b5 !important;
}

.btn-primary {
    background-color: #ff6f61; /* Orange corail */
    border-color: #ff6f61;
}

.btn-secondary {
    background-color: #eee; /* Gris clair */
    border-color: #eee;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
    background-color: #e66054; /* Orange corail plus foncé */
    border-color: #e66054;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active {
    background-color: #ddd; /* Gris légèrement plus foncé */
    border-color: #ddd;
}

.menu-prompts {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
}

.menu-prompts-bt-chat {
    display: flex;
    justify-content: right;
    align-items: center;
}

/* Exemple pour ajuster l'alignement et les tailles pour les écrans mobiles */
@media (max-width: 576px) {

    .container {
        padding: 0 !important;
    }

    .list-group-item {
        font-size: 0.9em; /* Réduire la taille de la police pour mobile */
        margin-bottom: 20px; /* Ajouter un peu plus d'espace entre les items */
    }

    .menu-prompts {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
        margin-top: 32px;
    }

    .menu-prompts-bt-chat {
        margin-top: 32px;
    }


    .prompt-title {
        font-size: 0.8em; /* Adapter la taille du titre */
        margin-bottom: 10px;
        border-bottom: 1px solid rgba(0,0,0,0.1);
    }

    .level-text {
        font-size: 0.8em; /* Ajuster la taille du texte pour le niveau */
    }

    .category-text {
        font-size: 0.8em; /* Ajuster la taille du texte pour la catégorie */
    }

    .btn {
        padding: 0.5rem 0.75rem; /* Légèrement augmenter le padding pour l'accessibilité */
    }

    .input-group .form-control {
        font-size: 0.875em; /* Réduire la taille du texte dans l'input */
    }

    /* Ajuster les icônes */
    .phosphor-icon {
        width: 20px; /* Réduire la taille des icônes pour mobile */
        height: 20px;
    }
}
