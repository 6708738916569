.dark-modal .modal-content {
    background-color: #eee; /* Gris clair */
    color: #000; /* Texte noir pour contraste */
    border-radius: 10px;
    padding: 20px;
}

.dark-modal .modal-header, .dark-modal .modal-footer {
    border: none;
}

.dark-modal .modal-title {
    color: #000; /* Texte noir pour contraste */
}

.modal-80w {
    max-width: 80%;
    width: 80%;
}

.input-question {
    background-color: #fff; /* Blanc */
    border: 1px solid #ff6f61; /* Bordure orange corail */
    color: #000; /* Texte noir pour contraste */
    border-radius: 5px;
    padding: 10px;
    width: calc(100% - 200px);
    margin-right: 10px;
}

.input-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.response-container {
    background-color: #fff; /* Blanc */
    border-radius: 5px;
    max-height: 300px;
    overflow-y: auto;
    padding: 15px;
    border: 1px solid #ff6f61; /* Bordure orange corail */
}

button:focus {
    outline: none;
    box-shadow: none;
}

button {
    margin-left: 10px;
    background-color: #ff6f61; /* Orange corail */
    border: none;
    color: #fff; /* Texte blanc */
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
}

button:hover {
    background-color: #e66054; /* Orange corail plus foncé */
}

input[type="text"]::placeholder {
    color: #aaa;
}

@media (max-width: 576px) {

    .dark-modal .modal-content {
        background-color: #eee;
        color: #000;
        border-radius: 10px;
        padding: 10px;
    }

    .modal-80w {
        max-width: unset;
        width: unset;
    }
}
