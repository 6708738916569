@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

:root {
  --primary-color: #272931;
  --secondary-color: #eee;
  --text-color: #333;
  --background-color: #00ff8c;
  --swiper-theme-color: var(--background-color) !important;
  --scrollbar-width: 16px;
}

/* Global Styles */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  font-family: "Outfit", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: var(--text-color);
  background-size: cover;
  background-position: center;
  scroll-behavior: smooth;
  animation: fadein 1s;
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
  display: none;
}

/* Progress Bar */
.progressbar.green svg {
  margin-top: 16px;
  border-radius: 6px !important;
  height: 4px !important;
  background-color: var(--background-color);
}

.progressbar.green svg path:last-child {
  stroke: var(--primary-color);
}

.progress-container {
  height: 12px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.progress-bar, .progress-bar-complet {
  height: 100%;
  border-radius: 5px;
  transition: width 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding: 4px;
  font-size: 0.8em;
  background: var(--primary-color);
}

/* Animations */
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes taadaa {
  0% {
    filter: brightness(800%);
  }
  100% {
    filter: brightness(100%);
  }
}

/* Scroll Reveal */
.scroll-reveal {
  opacity: 0;
  transform: translateY(420px) scale(2);
  filter: blur(6px);
  transition: opacity 0.8s, transform 0.8s, filter 0.8s;
}

.scroll-reveal.is-visible {
  opacity: 1;
  transform: translateY(0) scale(1);
  filter: blur(0);
}

/* Text and Links */

h4 {
  color: #666;
  margin: 10px 0 10px 0;
  font-size: 1.2em;
}

p {
  font-weight: 200;
  color: var(--text-color);
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Wrapper and Content */
#wrapper #content-wrapper {
  background-color: var(--secondary-color);
  width: 100%;
  overflow-x: hidden;
}

/* Navbar */
.navbar {
  background-color: var(--primary-color);
}

.bg-gradient-primary {
  background-color: var(--primary-color) !important;
  background-image: none !important;
  background-size: cover;
}

.sidebar .nav-item.active .nav-link {
  font-weight: 400;
  font-size: 1.2em !important;
  padding: 0 12px 4px 12px !important;
}

.sidebar hr.sidebar-divider {
  margin: 0 0.5rem 0.5rem;
}

/* Table */
.table-bordered th, .table-bordered td {
  border: 1px solid var(--primary-color);
}

/* Buttons */

button {
  margin: 0px !important;
  background-color: #ff6f61;
  border: none;
  color: #fff;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}


.cursor-pointer {
  cursor: pointer;
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-primary {
  color: var(--text-color);
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}


.btn-secondary {
  color: var(--text-color);
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  padding: 6px;
  margin: 10px;
  border-radius: 32px;
}

.btn-outline-primary {
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active {
  color: var(--text-color);
  background-color: var(--primary-color);
  box-shadow: 0 0 0 0.2rem rgba(255, 111, 97, 0.38);
}

/* Modern Button Style */
.btn-main {
  position: relative;
  padding: 6px 30px 12px;
  background: var(--primary-color);
  color: var(--text-color);
  border: 1px solid transparent;
  border-radius: 0.25rem;
  overflow: hidden;
  transition: transform 0.3s;
}

.btn-main:hover {
  border-color: var(--primary-color);
  transform: translateY(-3px);
}

.btn-main:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--primary-color);
  z-index: -1;
  transition: transform 0.5s;
}

.btn-main:hover:after {
  transform: translate(3px, 6px) scale(1.05);
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: rgba(255, 111, 97, 0.38);
  border-color: rgba(255, 111, 97, 0.38);
}

/* Form Elements */
.form-group {
  position: relative;
  margin-top: 30px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  color: var(--text-color);
  background-color: var(--primary-color);
}

.form-label {
  margin-top: 32px;
}

.form-control:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 0.2rem rgba(255, 111, 97, 0.38);
}

.form-control:disabled, .form-control[readonly] {
  background-color: var(--secondary-color);
  opacity: 1;
}

/* Cards */
.card {
  background-color: transparent;
  border-radius: 0.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.card-header {
  background-color: var(--primary-color);
  border-bottom: none;
  margin-bottom: 4px;
  color: var(--text-color);
}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--text-color);
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.pagination .btn {
  margin: 0 5px;
}

.pagination span {
  font-size: 16px;
}

/* Dropdown */
.pagination {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 20px !important;
}

.pagination .btn {
  margin: 0 5px;
}

.pagination span {
  font-size: 16px;
}

.dropdown-item {
  padding: 0 4px 0 2px;
  font-weight: normal;
  font-size: 0.85em !important;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: var(--text-color) !important;
  text-align: inherit;
  white-space: nowrap;
  border: 0;
}

.dropdown-menu {
  background: var(--secondary-color);
  box-shadow: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
  6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
  12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
  22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
  41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
  100px 100px 80px rgba(0, 0, 0, 0.07);
  font-size: 1.2em !important;
  border: none !important;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #fff !important;
  text-decoration: none;
  background-color: var(--primary-color);
}

/* Alerts */
.alert {
  margin-top: 1rem;
  background-color: #ffcc00;
  color: #1c1c1e;
  border-radius: 0.5rem;
}

/* Shadows */
.shadow {
  box-shadow: rgba(50, 50, 93, 0.02) 0 2px 5px -1px, rgba(0, 0, 0, 0.05) 0 1px 6px -1px;
}

.highlighted {
  background-color: rgba(0, 255, 140, 0.13);
  font-weight: bold;
  border-radius: 6px;
  padding: 6px;
}

.conversation-history {
  max-height: 300px;
  overflow-y: auto;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin-bottom: 20px;
}

.chat-bubble {
  padding: 10px;
  margin: 5px 0;
  border-radius: 15px;
  word-wrap: break-word;
}

.user-bubble {
  background-color: #dcf8c6;
  align-self: flex-end;
  text-align: right;
  color: #333;
}

.bot-bubble {
  background-color: #f1f0f0;
  align-self: flex-start;
  text-align: left;
  color: #555;
}

.input-question {
  border-radius: 25px;
  padding-left: 15px;
}

.start-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.audio-container {
  text-align: center;
  margin-top: 20px;
}

button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}


.audio_stop {
  background-color: rgba(0, 255, 140, 0.7);
  border-color: #00ff8c;
}

.audio_go {
  background-color: rgba(230, 96, 84, 0.7);
  border-color: #e66054;
}

.prompts {
  display: flex;
  justify-content: space-between;
}

/* Exemple pour ajuster l'alignement et les tailles pour les écrans mobiles */
@media (max-width: 576px) {
  .list-group-item {
    .container {
      padding: 0px;
    }

    .prompts {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
      padding-left: 0rem;
      padding-right: 0rem;
    }

  }

  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 2px;
  }

}
